section{
    .ver-galeria{
        width: 100%;
        height: auto;
        margin: 100px;
        display: flex;
        flex-wrap: wrap;
        .container-img{
            width: 100%;
            display: flex;
            justify-content: center;
            order: 1;
            img{
                width: 100%;
            }
        }
        .container-ver-galeria{
            width: 100%;
            display: flex;
            justify-content: center;
            order: 2;
        }
        .info-ver-galeria{
            width: 100%;
            background-color: $colorGray;
            padding: 50px;
            h2{
                width: 100%;
                font-size: 40px;
                line-height: 40px;
            }
            h4{
                width: 100%;
                font-size: 12px;
            }
            article{
                margin: 30px 0px;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .ver-galeria{
            width: 100%;
            height: auto;
            margin: 100px;
            display: flex;
            .container-img{
                width: 50%;
                display: flex;
                justify-content: right;
                overflow: hidden;
                img{
                    max-width: 500px;
                }
            }
            .container-ver-galeria{
                width: 50%;
                position: absolute;
                margin-left: 35%;
                margin-top: 200px;
                z-index: 10;
            }
            .info-ver-galeria{
                width: auto;
                background-color: $colorGray;
                padding: 50px;
                h2{
                    width: 100%;
                    font-size: 40px;
                    line-height: 40px;
                }
                h4{
                    width: 100%;
                    font-size: 12px;
                }
                article{
                    margin: 30px 0px;
                    font-size: 14px;
                }
            }
        }
    }
}