@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Jost", sans-serif;
  font-weight: 300;
  color: #333;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.bold {
  font-weight: 700;
}

body {
  width: 100%;
  background-color: #fff;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.bkg-gris {
  background-color: #eaeaea;
}

.btnB {
  width: auto;
  height: auto;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #fff;
  font-size: 22px;
  color: #fff;
}

.btnB:hover {
  background-color: #fff;
  color: #333;
  transition: 0.9s;
  cursor: pointer;
}

.btnN {
  width: auto;
  height: auto;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #333;
  font-size: 22px;
  color: #333;
}

.btnN:hover {
  background-color: #333;
  color: #fff;
  transition: 0.9s;
  cursor: pointer;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.go-up {
  position: fixed;
  background-color: #333;
  right: 0;
  bottom: 0;
  margin: 20px;
  font-size: 20px;
  z-index: 1000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.go-up a {
  display: flex;
  align-items: center;
}
.go-up svg {
  fill: #fff;
}

nav {
  width: 100%;
  height: auto;
  font-size: 16px;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  z-index: 100;
}
nav .buttons-open {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 60px;
  border-top: 1px solid #333;
}
nav .buttons-open .button {
  width: 100%;
  height: auto;
  padding: 20px 30px;
  color: #333;
  text-align: right;
  background-color: #fff;
}
nav .buttons-open .button:hover {
  width: 100%;
  height: auto;
  color: #333;
  background-color: #eaeaea;
  transition: 0.3s;
}
nav .buttons-closed {
  display: none;
}
nav .info {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
}
nav .info .logo {
  width: 160px;
}
nav .info .info-detalle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
nav .info .info-detalle .button {
  padding: 5px 0px;
  color: #fff;
  font-size: 12px;
}
nav .info .info-detalle .call {
  padding: 5px 0px;
  font-size: 12px;
  color: #fff;
}
nav .info .info-detalle .info-redes {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
nav .info .info-detalle .info-redes .social {
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #333;
  border-radius: 50%;
}
nav .info .info-detalle .info-redes .social svg {
  fill: #fff;
  width: 26px;
  font-size: 26px;
}
nav .info .info-detalle .info-redes .social:hover {
  background-color: #fff;
  transition: 0.9s;
}
nav .info .info-detalle .info-redes .social:hover svg {
  fill: #333;
  transition: 0.9s;
}
nav .container-toggle {
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
}
nav .container-toggle .toggle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 5px;
  margin-right: 20px;
}
nav .container-toggle .toggle svg {
  width: 100%;
  fill: #333;
}

@media only screen and (min-width: 1000px) {
  nav .buttons-open {
    border-top: none;
  }
  nav .buttons-open .button {
    text-align: center;
  }
  nav .info {
    justify-content: space-between;
  }
  nav .info .logo {
    width: 200px;
  }
  nav .info .info-detalle .button {
    font-size: 18px;
  }
  nav .info .info-detalle .call {
    font-size: 22px;
  }
  nav .container-toggle {
    background-color: transparent;
  }
  nav .container-toggle .toggle svg {
    fill: #fff;
  }
}
section .banner-section {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #999;
}

section .banner-home {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
section .banner-home h1 {
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  padding: 50px;
}
section .banner-home-contacto {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section .banner-home-clinique {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
section .banner-home-clinique img {
  margin-bottom: 50px;
  max-width: 80%;
}

@media only screen and (min-width: 1000px) {
  section .banner-home h1 {
    font-size: 50px;
  }
  section .banner-home-clinique {
    background-position: center;
  }
}
section .intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .intro h2 {
  width: 100%;
  text-align: center;
  font-size: 34px;
}
section .intro h3 {
  width: 100%;
  text-align: center;
  font-size: 30px;
}
section .intro h4 {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
section .intro article {
  width: 90%;
  font-size: 14px;
  line-height: 24px;
  padding: 20px;
}

@media only screen and (min-width: 1000px) {
  section .intro h2 {
    font-size: 40px;
  }
  section .intro article {
    width: 800px;
  }
}
section .procedimientos-home {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
  overflow: hidden;
}
section .procedimientos-home .container {
  width: 300px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section .procedimientos-home .container .btnB {
  margin: 30px;
}
section .procedimientos-home .container:hover {
  transform: scale(1.03);
  transition: 0.9s;
}

section .federico-luque {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 100px;
  padding: 30px;
}
section .federico-luque .img {
  width: 100%;
}
section .federico-luque .img img {
  width: 100%;
}
section .federico-luque .info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
section .federico-luque .info h2 {
  width: 100%;
  text-align: left;
  font-size: 40px;
}
section .federico-luque .info h4 {
  width: 100%;
  text-align: left;
  font-size: 12px;
}
section .federico-luque .info article {
  width: 100%;
  font-size: 24px;
  line-height: 34px;
  padding: 20px 0px;
}
section .federico-luque .info .btnN {
  width: 200px;
}

@media only screen and (min-width: 1000px) {
  section .federico-luque .img {
    width: 500px;
  }
  section .federico-luque .info {
    width: 500px;
  }
}
section .ver-galeria {
  width: 100%;
  height: auto;
  margin: 100px;
  display: flex;
  flex-wrap: wrap;
}
section .ver-galeria .container-img {
  width: 100%;
  display: flex;
  justify-content: center;
  order: 1;
}
section .ver-galeria .container-img img {
  width: 100%;
}
section .ver-galeria .container-ver-galeria {
  width: 100%;
  display: flex;
  justify-content: center;
  order: 2;
}
section .ver-galeria .info-ver-galeria {
  width: 100%;
  background-color: #eaeaea;
  padding: 50px;
}
section .ver-galeria .info-ver-galeria h2 {
  width: 100%;
  font-size: 40px;
  line-height: 40px;
}
section .ver-galeria .info-ver-galeria h4 {
  width: 100%;
  font-size: 12px;
}
section .ver-galeria .info-ver-galeria article {
  margin: 30px 0px;
  font-size: 14px;
}

@media only screen and (min-width: 1000px) {
  section .ver-galeria {
    width: 100%;
    height: auto;
    margin: 100px;
    display: flex;
  }
  section .ver-galeria .container-img {
    width: 50%;
    display: flex;
    justify-content: right;
    overflow: hidden;
  }
  section .ver-galeria .container-img img {
    max-width: 500px;
  }
  section .ver-galeria .container-ver-galeria {
    width: 50%;
    position: absolute;
    margin-left: 35%;
    margin-top: 200px;
    z-index: 10;
  }
  section .ver-galeria .info-ver-galeria {
    width: auto;
    background-color: #eaeaea;
    padding: 50px;
  }
  section .ver-galeria .info-ver-galeria h2 {
    width: 100%;
    font-size: 40px;
    line-height: 40px;
  }
  section .ver-galeria .info-ver-galeria h4 {
    width: 100%;
    font-size: 12px;
  }
  section .ver-galeria .info-ver-galeria article {
    margin: 30px 0px;
    font-size: 14px;
  }
}
section .dr-luque {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .dr-luque h2 {
  text-align: center;
  font-size: 34px;
}
section .dr-luque h3 {
  text-align: center;
  font-size: 30px;
}
section .dr-luque h4 {
  text-align: center;
  font-size: 12px;
}
section .dr-luque img {
  max-width: 80%;
  margin: 30px;
}
section .dr-luque article {
  max-width: 1000px;
  font-size: 18px;
  line-height: 26px;
  padding: 20px 40px;
}

@media only screen and (min-width: 1000px) {
  section .dr-luque h2 {
    font-size: 40px;
  }
}
section .procedimientos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .procedimientos h2 {
  text-align: center;
  font-size: 34px;
}
section .procedimientos h3 {
  text-align: left;
  font-size: 30px;
}
section .procedimientos h4 {
  text-align: center;
  font-size: 12px;
}
section .procedimientos img {
  max-width: 80%;
  margin: 30px;
}
section .procedimientos .list-procedimientos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 50px;
}
section .procedimientos .list-procedimientos .container-procedimientos {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 20px;
}
section .procedimientos .list-procedimientos .container-procedimientos .button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 5px 0px;
  margin: 5px 0px;
  text-align: left;
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  section .procedimientos h2 {
    font-size: 40px;
  }
}
section .procedimiento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .procedimiento h2 {
  width: 100%;
  text-align: center;
  font-size: 34px;
}
section .procedimiento h4 {
  width: 100%;
  text-align: center;
  font-size: 12px;
}
section .procedimiento .container-procedimiento {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
}
section .procedimiento .container-procedimiento .img {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}
section .procedimiento .container-procedimiento .img img {
  max-width: 80%;
}
section .procedimiento .container-procedimiento .detalle-procedimiento {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section .procedimiento .container-procedimiento .detalle-procedimiento .titulo {
  font-size: 26px;
  font-weight: 400;
}
section .procedimiento .container-procedimiento .detalle-procedimiento article {
  max-width: 100%;
  padding: 0px 30px 20px 30px;
  font-size: 18px;
  line-height: 36px;
}

@media only screen and (min-width: 1000px) {
  section .procedimiento h2 {
    font-size: 40px;
  }
  section .procedimiento .container-procedimiento {
    flex-direction: row;
    align-items: center;
  }
  section .procedimiento .container-procedimiento .img {
    width: 50%;
    justify-content: right;
    padding: 0px 20px;
  }
  section .procedimiento .container-procedimiento .img img {
    max-width: 500px;
  }
  section .procedimiento .container-procedimiento .detalle-procedimiento {
    width: 50%;
  }
  section .procedimiento .container-procedimiento .detalle-procedimiento article {
    max-width: 600px;
    padding: 0px 20px 20px 20px;
  }
}
section .quirofanos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .quirofanos h2 {
  text-align: center;
  font-size: 34px;
}
section .quirofanos h3 {
  text-align: center;
  font-size: 24px;
}
section .quirofanos h4 {
  text-align: center;
  font-size: 12px;
}
section .quirofanos img {
  max-width: 80%;
  margin: 30px;
}
section .quirofanos .container-quirofanos {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 50px 0px;
}
section .quirofanos .container-quirofanos article {
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
}
section .quirofanos .container-quirofanos .detalle-quirofano {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
section .quirofanos .container-quirofanos .detalle-quirofano svg {
  width: 90px;
  margin: 20px;
  fill: #eaeaea;
}
section .quirofanos .container-quirofanos .detalle-quirofano p {
  text-align: center;
  padding: 15px;
}

@media only screen and (min-width: 1000px) {
  section .quirofanos h2 {
    font-size: 40px;
  }
}
section .testimonios {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .testimonios h2 {
  text-align: center;
  font-size: 34px;
}
section .testimonios h4 {
  text-align: center;
  font-size: 12px;
}
section .testimonios .swiper-container {
  width: 95vw;
  height: auto;
  padding: 50px;
  display: flex;
}
section .testimonios .swiper-container .swiper-button-next, section .testimonios .swiper-container .swiper-button-prev {
  color: #eaeaea;
}
section .testimonios .swiper-container .swiper-pagination-bullet-active {
  background-color: #333;
}
section .testimonios .swiper-container .swiper-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
section .testimonios .swiper-container .swiper-slide h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
}
section .testimonios .swiper-container .swiper-slide article {
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
}

@media only screen and (min-width: 1000px) {
  section .testimonios h2 {
    font-size: 40px;
  }
}
section .galeria {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px;
}
section .galeria h2 {
  text-align: center;
  font-size: 34px;
}
section .galeria h4 {
  text-align: center;
  font-size: 12px;
}
section .galeria .categorias-galeria {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}
section .galeria .categorias-galeria .links {
  padding: 15px;
  margin: 10px;
  border-bottom: solid 2px transparent;
}
section .galeria .categorias-galeria .activeLinks {
  padding: 15px;
  margin: 10px;
  border-bottom: solid 2px #333;
  background-color: #333;
  color: #E8E8E8;
}
section .galeria .categorias-galeria .links:hover {
  border-bottom: solid 2px #333;
}
section .galeria .swiper-container {
  width: 95vw;
  height: auto;
  padding: 50px;
  display: flex;
}
section .galeria .swiper-container .swiper-button-next, section .galeria .swiper-container .swiper-button-prev {
  color: #333;
}
section .galeria .swiper-container .swiper-pagination-bullet-active {
  background-color: #333;
}
section .galeria .swiper-container .swiper-slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
section .galeria .swiper-container .swiper-slide .container-galeria {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #eaeaea;
  padding: 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
section .galeria .swiper-container .swiper-slide .container-galeria .img {
  width: 300px;
}
section .galeria .swiper-container .swiper-slide .container-galeria .img img {
  max-width: 100%;
}
section .galeria .swiper-container .swiper-slide .container-galeria .info-galeria {
  width: auto;
  background-color: #eaeaea;
  padding: 0px 40px;
}
section .galeria .swiper-container .swiper-slide .container-galeria .info-galeria article {
  width: 300px;
  font-size: 16px;
  line-height: 24px;
}
section .galeria .swiper-container .swiper-slide .container-galeria .info-galeria article .titulo {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (min-width: 1000px) {
  section .galeria h2 {
    font-size: 40px;
  }
  section .galeria .swiper-container {
    width: 95vw;
    height: auto;
    padding: 50px;
    display: flex;
  }
  section .galeria .swiper-container .swiper-button-next, section .galeria .swiper-container .swiper-button-prev {
    color: #333;
  }
  section .galeria .swiper-container .swiper-pagination-bullet-active {
    background-color: #333;
  }
  section .galeria .swiper-container .swiper-slide .container-galeria {
    padding: 20px;
  }
}
section .marcas {
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
section .marcas img {
  min-width: 50px;
  max-width: 200px;
  margin: 0px 10px;
}

section .historia-clinica {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section .historia-clinica h3 {
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}
section .historia-clinica h4 {
  width: 100%;
  font-size: 12px;
}
section .historia-clinica form {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
section .historia-clinica form .container-form-historia-clinica {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}
section .historia-clinica form .container-form-historia-clinica .box-historia-clinica {
  width: 300px;
  margin: 10px 30px;
}
section .historia-clinica form .container-form-historia-clinica h3 {
  margin: 30px;
  font-weight: 500;
}
section .historia-clinica form input, section .historia-clinica form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  margin: 5px 0px 20px 0px;
  font-size: 16px;
  border: 1px solid #333;
}
section .historia-clinica form p {
  color: red;
  margin-top: -15px;
  margin-bottom: 15px;
  font-weight: 400;
}
section .historia-clinica form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .historia-clinica form .radiobutton {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
}
section .historia-clinica form .radiobutton label {
  margin-right: 15px;
}
section .historia-clinica form .radiobutton input {
  width: auto;
  margin: 15px 40px 15px 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
section .historia-clinica form .validar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
}
section .historia-clinica form .validar label {
  width: 400px;
  font-weight: 800;
  padding: 30px;
  text-align: center;
}
section .historia-clinica form .checkbox {
  width: 30px;
  height: 30px;
  margin: 15px;
}
section .historia-clinica form .btnN {
  border: 2px solid #333;
  width: 160px;
}
section .historia-clinica .container-img {
  width: 500px;
}
section .historia-clinica .container-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1000px) {
  section .historia-clinica .container-form form {
    width: 500px;
  }
}
.container-historia-clinica-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-historia-clinica-detalle h2 {
  font-size: 36px;
  margin: 10px;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
}
.container-historia-clinica-detalle h3 {
  font-size: 26px;
  margin: 10px;
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
}
.container-historia-clinica-detalle table {
  width: 700px;
  text-align: center;
}
.container-historia-clinica-detalle table .w100 {
  width: 100%;
}
.container-historia-clinica-detalle table .w75 {
  width: 75%;
}
.container-historia-clinica-detalle table .w50 {
  width: 50%;
}
.container-historia-clinica-detalle table .w33 {
  width: 33.33%;
}
.container-historia-clinica-detalle table .w25 {
  width: 25%;
}
.container-historia-clinica-detalle table tr {
  width: 100%;
  height: auto;
  font-size: 16px;
}
.container-historia-clinica-detalle table tr span {
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #333;
  font-family: "Roboto", sans-serif;
}
.container-historia-clinica-detalle table tr td {
  border: 1px solid #333;
  padding: 5px 10px;
  line-height: 26px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.reserva {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reserva .container-reserva {
  width: 80%;
  height: auto;
  margin: 100px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reserva .container-reserva h2 {
  font-size: 34px;
}
.reserva .container-reserva h4 {
  font-size: 14px;
}
.reserva .container-reserva form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
}
.reserva .container-reserva form article {
  width: 100%;
  height: auto;
  margin: 20px;
  font-size: 16px;
}
.reserva .container-reserva form .fecha-form {
  width: 62px;
  height: auto;
  border: none;
  margin-left: 6px;
  font-size: 14px;
}
.reserva .container-reserva form .input-form {
  width: 100%;
  margin: 10px 0px 20px 0px;
  border: none;
  border-bottom: 1px solid #333;
  font-size: 16px;
  font-weight: 500;
}
.reserva .container-reserva form p {
  color: red;
  font-weight: 600;
}
.reserva .container-reserva form .sigCanvas {
  width: 330px;
  height: 200px;
  border: 1px solid #333;
}
.reserva .container-reserva form .container-btn-canvas {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reserva .container-reserva form .container-btn-canvas .btnN {
  margin: 15px;
  font-size: 16px;
  font-weight: 500;
}

.container-reserva-detalle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-reserva-detalle h2 {
  font-size: 36px;
  margin: 10px;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
}
.container-reserva-detalle table {
  width: 700px;
  text-align: center;
}
.container-reserva-detalle table .w100 {
  width: 100%;
}
.container-reserva-detalle table tr {
  width: 100%;
  height: auto;
}
.container-reserva-detalle table tr span {
  font-weight: 500;
}
.container-reserva-detalle table tr td {
  padding: 5px 10px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}
.container-reserva-detalle table tr td img {
  min-height: 200px;
  max-height: 200px;
  margin: 30px 0px;
}

@media only screen and (min-width: 1000px) {
  .reserva {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reserva .container-reserva {
    width: 500px;
    height: auto;
    margin: 100px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reserva .container-reserva h2 {
    font-size: 34px;
  }
  .reserva .container-reserva h4 {
    font-size: 14px;
  }
  .reserva .container-reserva form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
  }
  .reserva .container-reserva form article {
    width: 100%;
    height: auto;
    margin: 20px;
    font-size: 16px;
  }
  .reserva .container-reserva form .fecha-form {
    width: 62px;
    height: auto;
    border: none;
    margin-left: 6px;
    font-size: 14px;
  }
  .reserva .container-reserva form .input-form {
    width: 100%;
    margin: 10px 0px 20px 0px;
    border: none;
    border-bottom: 1px solid #333;
    font-size: 16px;
    font-weight: 500;
  }
}
section .home-banner-dr-luque {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: 77%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 260px;
}
section .home-banner-dr-luque .logo-luque-banner-clinique {
  width: 200px;
  margin: 50px;
}

@media only screen and (min-width: 1000px) {
  section .home-banner-dr-luque {
    background-position: center;
    padding-top: 0;
  }
  section .home-banner-dr-luque .logo-luque-banner-clinique {
    width: 300px;
  }
}
section .banner-clinique {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section .clinique {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
section .clinique h2 {
  text-align: center;
  font-size: 34px;
}
section .clinique h3 {
  text-align: center;
  font-size: 30px;
}
section .clinique h4 {
  text-align: center;
  font-size: 12px;
}
section .clinique .clinique-logo {
  display: flex;
  justify-content: center;
}
section .clinique .clinique-logo img {
  max-width: 80%;
  margin: 30px;
}
section .clinique .container-intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
section .clinique .container-intro video {
  margin-top: 50px;
}

@media only screen and (min-width: 1000px) {
  section .dr-luque h2 {
    font-size: 40px;
  }
}
section .contacto-clinique {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #8F729F;
  padding: 50px;
}
section .contacto-clinique h2 {
  width: 100%;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
}
section .contacto-clinique h4 {
  width: 100%;
  font-size: 12px;
  color: #fff;
}
section .contacto-clinique .container-form {
  padding: 20px;
}
section .contacto-clinique .container-form form {
  width: 100%;
  margin-top: 20px;
}
section .contacto-clinique .container-form form input, section .contacto-clinique .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  margin: 5px 0px;
  font-size: 16px;
}
section .contacto-clinique .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .contacto-clinique .container-form form .btnN {
  border: 2px solid #fff;
  width: 160px;
  color: #fff;
}
section .contacto-clinique .container-form form .btnN:hover {
  border: 2px solid #fff;
  width: 160px;
  color: #333;
  background-color: #fff;
}
section .contacto-clinique .container-img {
  width: 500px;
  padding: 30px;
}
section .contacto-clinique .container-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1000px) {
  section .contacto-clinique .container-form form {
    width: 500px;
  }
}
section .tratamientos {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: #eaeaea;
  position: relative;
}
section .tratamientos .instagram {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  padding: 40px;
}
section .tratamientos .instagram h2 {
  text-align: center;
  font-size: 34px;
  color: #333;
}
section .tratamientos .instagram h2:hover {
  color: #666;
  transition: 0.6s;
}
section .tratamientos .instagram .social {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50%;
}
section .tratamientos .instagram .social svg {
  fill: #333;
  width: 26px;
  font-size: 26px;
}
section .tratamientos .instagram .social:hover svg {
  fill: #666;
  transition: 0.6s;
}
section .tratamientos .container-tratamientos {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 150px;
}
section .tratamientos .container-tratamientos .banner-tratamientos {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
section .tratamientos .container-tratamientos .banner-tratamientos h2 {
  font-weight: 900;
  font-size: 36px;
}
section .tratamientos .container-tratamientos .container {
  width: 90%;
  height: auto;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  z-index: 900;
}
section .tratamientos .container-tratamientos .container video {
  width: 100%;
  height: auto;
}
section .tratamientos .container-tratamientos .container .img {
  width: 100%;
  height: auto;
}
section .tratamientos .container-tratamientos .container .img img {
  width: 100%;
  height: auto;
}
section .tratamientos .container-tratamientos .container .swiper-container {
  width: 100%;
  height: auto;
  display: flex;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-button-next, section .tratamientos .container-tratamientos .container .swiper-container .swiper-button-prev {
  color: #333;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-pagination-bullet-active {
  background-color: #333;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-slide {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-slide h3 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-slide h4 {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
section .tratamientos .container-tratamientos .container .swiper-container .swiper-slide article {
  padding: 20px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

@media only screen and (min-width: 1000px) {
  section .tratamientos .container-tratamientos .container {
    width: 80%;
    flex-direction: row;
  }
  section .tratamientos .container-tratamientos .container video {
    width: auto;
    height: 700px;
  }
  section .tratamientos .container-tratamientos .container .img {
    width: auto;
    height: 700px;
  }
  section .tratamientos .container-tratamientos .container .img img {
    width: auto;
    height: 700px;
  }
}
section .contacto {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #eaeaea;
  padding: 50px;
}
section .contacto h2 {
  width: 100%;
  font-size: 40px;
  line-height: 40px;
}
section .contacto h4 {
  width: 100%;
  font-size: 12px;
}
section .contacto .container-form {
  padding: 20px;
}
section .contacto .container-form form {
  width: 100%;
  margin-top: 20px;
}
section .contacto .container-form form input, section .contacto .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  margin: 5px 0px;
  font-size: 16px;
}
section .contacto .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .contacto .container-form form .btnN {
  border: 2px solid #333;
  width: 160px;
}
section .contacto .container-img {
  width: 500px;
}
section .contacto .container-img img {
  max-width: 100%;
}

@media only screen and (min-width: 1000px) {
  section .contacto .container-form form {
    width: 500px;
  }
}
footer {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
footer .container {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
}
footer .container .logo {
  width: 200px;
  display: flex;
  align-items: flex-start;
}
footer .container .logo img {
  width: 200px;
  height: auto;
  margin-top: 20px;
}
footer .container div {
  width: 200px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}
footer .container div .button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 15px 0px 5px 40px;
}
footer .container div .button:hover {
  color: #999;
  transition: 0.3s;
}
footer .container .social {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin: 20px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #333;
  border-radius: 50%;
}
footer .container .social svg {
  fill: #fff;
  width: 26px;
  font-size: 26px;
}
footer .container .social:hover {
  background-color: #fff;
  transition: 0.9s;
}
footer .container .social:hover svg {
  fill: #333;
  transition: 0.9s;
}
footer .miembro {
  width: 100%;
  height: auto;
  background-color: #eaeaea;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
footer .miembro img {
  width: 120px;
  margin: 5px 20px;
}/*# sourceMappingURL=styles.css.map */