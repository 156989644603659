@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap');

$colorMain: #333;
$colorSecondary: #999;
$colorWhite: #fff;
$colorGray: #eaeaea;

*{
	padding: 0;
	margin: 0;
	font-family: 'Jost', sans-serif;
	font-weight: 300;
	color: $colorMain;
	text-decoration: none;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

.bold{
	font-weight: 700;
}

body{
	width: 100%;
	background-color: $colorWhite;
}

.appContainer{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main{
	flex-grow: 1;
}

section{
	width: 100%;
	max-width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.bkg-gris{
	background-color: $colorGray;
}

.btnB{
	width: auto;
	height: auto;
	padding: 10px 20px;
	background-color: transparent;
	border: 2px solid $colorWhite;
	font-size: 22px;
	color: $colorWhite;
}
.btnB:hover{
	background-color: $colorWhite;
	color: $colorMain;
	transition: 0.9s;
	cursor: pointer;
}

.btnN{
	width: auto;
	height: auto;
	padding: 10px 20px;
	background-color: transparent;
	border: 2px solid $colorMain;
	font-size: 22px;
	color: $colorMain;
}
.btnN:hover{
	background-color: $colorMain;
	color: $colorWhite;
	transition: 0.9s;
	cursor: pointer;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.go-up{
	position: fixed;
	background-color: $colorMain;
	right: 0;
	bottom: 0;
	margin: 20px;
	font-size: 20px;
	z-index: 1000;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	a{
		display: flex;
		align-items: center;
	}
	svg{
		fill: #fff;
	}
}

@import 'components/NavBar/navbar';

@import 'components/Banners/banner';

@import 'components/Home/Banners/banner';
@import 'components/Home/Intro/intro';
@import 'components/Home/Procedimientos/procedimientos-home';
@import 'components/Home/FedericoLuque/federico-luque';
@import 'components/Home/VerGaleria/ver-galeria';

@import 'components/DrLuque/dr-luque';
@import 'components/Procedimientos/procedimientos';
@import 'components/ProcedimientosDetalle/procedimientos-detalle';
@import 'components/Quirofanos/quirofanos';
@import 'components/Testimonios/testimonios';
@import 'components/Galeria/galeria';

@import 'components/Marcas/marcas';

@import 'components/HistoriaClinica/historia-clinica';
@import 'components/HistoriaClinicaDetalle/historia-clinica-detalle';

@import 'components/Reserva/reserva';

@import 'components/Clinique/BannerDrLuque/banner-dr-luque';
@import 'components/Clinique/clinique';
@import 'components/Clinique/ContactoClinique/contacto-form-clinique';
@import 'components/Clinique/Tratamientos/tratamientos';

@import 'components/Contacto/contacto';

@import 'components/Footer/footer';