.container-historia-clinica-detalle{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 36px;
        margin: 10px;
        font-family: 'Roboto', sans-serif;
        margin-top: 50px;
    }
    h3{
        font-size: 26px;
        margin: 10px;
        font-family: 'Roboto', sans-serif;
        margin-top: 30px;
    }
    table{
        width: 700px;
        text-align: center;
        .w100{
            width: 100%;
        }
        .w75{
            width: 75%;
        }
        .w50{
            width: 50%;
        }
        .w33{
            width: 33.33%;
        }
        .w25{
            width: 25%;
        }
        tr{
            width: 100%;
            height: auto;
            font-size: 16px;
            span{
                font-weight: 500;
                font-size: 16px;
                border-bottom: 1px solid $colorMain;
                font-family: 'Roboto', sans-serif;
            }
            td{
                border: 1px solid $colorMain;
                padding: 5px 10px;
                line-height: 26px;
                text-align: left;
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
            }
        }
    }
}