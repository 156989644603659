section{
    .dr-luque{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h3{
            text-align: center;
            font-size: 30px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        img{
            max-width: 80%;
            margin: 30px;
        }
        article{
            max-width: 1000px;
            font-size: 18px;
            line-height: 26px;
            padding: 20px 40px;
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .dr-luque{
            h2{
                font-size: 40px;
            }
        }
    }
}