nav{
  width: 100%;
  height: auto;
  font-size: 16px;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  z-index: 100;
  .buttons-open{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      margin-top: 60px;
      border-top: 1px solid $colorMain;
      .button{
        width: 100%;
        height: auto;
        padding: 20px 30px;
        color: $colorMain;
        text-align: right;
        background-color: $colorWhite;
      }
      .button:hover{
        width: 100%;
        height: auto;
        color: $colorMain;
        background-color: $colorGray;
        transition: 0.3s;
    }
  }
  .buttons-closed{
    display: none;
  }
  .info{
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      padding: 10px 40px;
      .logo{
          width: 160px;
      }
      .info-detalle{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
          .button{
              padding: 5px 0px;
              color: $colorWhite;
              font-size: 12px;
          }
          .call{
              padding: 5px 0px;
              font-size: 12px;
              color: $colorWhite;
          }
          .info-redes{
              display: flex;
              flex-direction: row;
              margin-top: 10px;
              .social{
                  width: 40px;
                  height: 40px;
                  padding: 10px;
                  margin: 3px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  background-color: $colorMain;
                  border-radius: 50%;
                  svg{
                      fill: $colorWhite;
                      width: 26px;
                      font-size: 26px;
                  }
              }
              .social:hover{
                  background-color: $colorWhite;
                  transition: 0.9s;
                  svg{
                      fill: $colorMain;
                      transition: 0.9s;
                  }
              }
          }
      }
  }
  .container-toggle{
    width: 100%;
    height: 60px;
    background-color: $colorWhite;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
    .toggle{
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      padding: 5px;
      margin-right: 20px;
      svg{
        width: 100%;
        fill: $colorMain;
      }
    }
  }
  
}

@media only screen and (min-width: 1000px){
  nav{
    .buttons-open{
      border-top: none;
      .button{
        text-align: center;
      }
    }
    .info{
        justify-content: space-between;
        .logo{
            width: 200px;
        }
        .info-detalle{
            .button{
                font-size: 18px;
            }
            .call{
                font-size: 22px;
            }
        }
    }
    .container-toggle{
      background-color: transparent;
      .toggle{
        svg{
          fill: $colorWhite;
        }
      }
    }
    
  }
}