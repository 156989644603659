section{
    .federico-luque{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 100px;
        padding: 30px;
        .img{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .info{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            h2{
                width: 100%;
                text-align: left;
                font-size: 40px;
            }
            h4{
                width: 100%;
                text-align: left;
                font-size: 12px;
            }
            article{
                width: 100%;
                font-size: 24px;
                line-height: 34px;
                padding: 20px 0px;
            }
            .btnN{
                width: 200px;
            }
        }        
    }
}

@media only screen and (min-width: 1000px){
    section{
        .federico-luque{
            .img{
                width: 500px;
            }
            .info{
                width: 500px;
            }        
        }
    }
}