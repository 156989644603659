section{
    .quirofanos{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h3{
            text-align: center;
            font-size: 24px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        img{
            max-width: 80%;
            margin: 30px;
        }
        .container-quirofanos{
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            margin: 50px 0px;
            article{
                width: 100%;
                text-align: center;
                padding: 20px;
                font-size: 16px;
                line-height: 28px;
            }
            .detalle-quirofano{
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px;
                svg{
                    width: 90px;
                    margin: 20px;
                    fill: $colorGray;
                }
                p{
                    text-align: center;
                    padding: 15px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .quirofanos{
            h2{
                font-size: 40px;
            }
        }
    }
}