section{
    .banner-section{
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $colorSecondary;
    }
}