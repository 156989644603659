section{
    .intro{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            width: 100%;
            text-align: center;
            font-size: 34px;
        }
        h3{
            width: 100%;
            text-align: center;
            font-size: 30px;
        }
        h4{
            width: 100%;
            text-align: center;
            font-size: 12px;
        }
        article{
            width: 90%;
            font-size: 14px;
            line-height: 24px;
            padding: 20px;
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .intro{
            h2{
                font-size: 40px;
            }
            article{
                width: 800px;
            }
        }
    }
}