section{
    .testimonios{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        .swiper-container {
            width: 95vw;
            height: auto;
            padding: 50px;
            display: flex;
            .swiper-button-next, .swiper-button-prev {
                color: $colorGray; 
            }
            .swiper-pagination-bullet-active{
                background-color: $colorMain;
            }
            .swiper-slide {	
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                h3{
                    text-align: center;
                    font-size: 30px;
                    font-weight: 500;
                }
                article{
                    padding: 20px;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .testimonios{
            h2{
                font-size: 40px;
            }
        }
    }
}


