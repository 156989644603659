section{
    .contacto{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: $colorGray;
        padding: 50px;
        h2{
            width: 100%;
            font-size: 40px;
            line-height: 40px;
        }
        h4{
            width: 100%;
            font-size: 12px;
        }
        .container-form{
            padding: 20px;
            form{
                width: 100%;
                margin-top: 20px;
                input, textarea{
                    width: 100%;
                    padding: 10px;
                    border: none;
                    margin: 5px 0px;
                    font-size: 16px;
                }
                textarea{
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100px;
                    max-height: 400px;
                }
                .btnN{
                    border: 2px solid $colorMain;
                    width: 160px;
                }
            }
        }
        .container-img{
            width: 500px;
            img{
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .contacto{
            .container-form{
                form{
                    width: 500px;
                }
            }
        }
    }
}