section{
    .banner-clinique{
        width: 100%;
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .clinique{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h3{
            text-align: center;
            font-size: 30px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        .clinique-logo{
            display: flex;
            justify-content: center;
            img{
                max-width: 80%;
                margin: 30px;
            }
        }
        .container-intro{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            video{
                margin-top: 50px;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .dr-luque{
            h2{
                font-size: 40px;
            }
        }
    }
}