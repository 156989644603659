footer{
	width: 100%;
	height: auto;
	background-color: $colorWhite;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	.container{
		width: 100%;
		height: auto;
		background-color: $colorWhite;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 50px;
		.logo{
			width: 200px;
			display: flex;
			align-items: flex-start;
			img{
				width: 200px;
				height: auto;
				margin-top: 20px;
			}
		}
		div{
			width: 200px;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 20px;
			.button{
				width: 100%;
				background-color: transparent;
				border: none;
				cursor: pointer;
				font-size: 16px;
				margin: 15px 0px 5px 40px;
			}
			.button:hover{
				color: #999;
				transition: 0.3s;
			}
		}
		.social{
			width: 50px;
			height: 50px;
			padding: 10px;
			margin: 20px 5px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			background-color: #333;
			border-radius: 50%;
			svg{
				fill: #fff;
				width: 26px;
				font-size: 26px;
			}
		}
		.social:hover{
			background-color: #fff;
			transition: 0.9s;
			svg{
				fill: $colorMain;
				transition: 0.9s;
			}
		}
	}
	.miembro{
		width: 100%;
		height: auto;
		background-color: $colorGray;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		img{
			width: 120px;
			margin: 5px 20px;
		}
	}
}