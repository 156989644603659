section{
    .banner-home{
        width: 100%;
        height: 900px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        h1{
            color: $colorWhite;
            font-weight: 300;
            font-size: 30px;
            text-align: center;
            padding: 50px;
        }
    }

    .banner-home-contacto{
        width: 100%;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner-home-clinique{
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        img{
            margin-bottom: 50px;
            max-width: 80%;
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .banner-home{
            h1{
                font-size: 50px;
            }
        }
        .banner-home-clinique{
            background-position: center;
        }
    }
}