section{
    .procedimientos-home{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin: 50px 0px;
        overflow: hidden;
        .container{
            width: 300px;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .btnB{
                margin: 30px;
            }
        }
        .container:hover{
            transform: scale(1.03);
            transition: 0.9s;
        }
    }
}