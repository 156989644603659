section{
    .marcas{
        width: 100%;
        background-color: $colorMain;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        img{
            min-width: 50px;
            max-width: 200px;
            margin: 0px 10px;
        }
    }
}