section{
    .historia-clinica{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3{
            width: 100%;
            font-size: 30px;
            line-height: 40px;
            text-align: center;
        }
        h4{
            width: 100%;
            font-size: 12px;
        }
        form{
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            .container-form-historia-clinica{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                padding: 20px;
                .box-historia-clinica{
                    width: 300px;
                    margin: 10px 30px;
                }
                h3{
                    margin: 30px;
                    font-weight: 500;
                }
            }
            input, textarea{
                width: 100%;
                padding: 10px;
                border: none;
                margin: 5px 0px 20px 0px;
                font-size: 16px;
                border: 1px solid $colorMain;
            }
            p{
                color: red;
                margin-top: -15px;
                margin-bottom: 15px;
                font-weight: 400;
            }
            textarea{
                min-width: 100%;
                max-width: 100%;
                min-height: 100px;
                max-height: 400px;
            }
            .radiobutton{
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                margin-bottom: 20px;
                label{
                    margin-right: 15px;
                }
                input{
                    width: auto;
                    margin: 15px 40px 15px 0px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
            .validar{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 30px;
                label{
                    width: 400px;
                    font-weight: 800;
                    padding: 30px;
                    text-align: center;
                }
            }
            .checkbox{
                    width: 30px;
                    height: 30px;
                    margin: 15px;
            }
            .btnN{
                border: 2px solid $colorMain;
                width: 160px;
            }
        }
        .container-img{
            width: 500px;
            img{
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .historia-clinica{
            .container-form{
                form{
                    width: 500px;
                }
            }
        }
    }
}