.reserva{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .container-reserva{
        width: 80%;
        height: auto;
        margin: 100px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2{
            font-size: 34px;
        }
        h4{
            font-size: 14px;
        }
        form{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 50px 0px;
            article{
                width: 100%;
                height: auto;
                margin: 20px;
                font-size: 16px;
            }
            .fecha-form{
                width: 62px;
                height: auto;
                border: none;
                margin-left: 6px;
                font-size: 14px;
            }
            .input-form{
                width: 100%;
                margin: 10px 0px 20px 0px;
                border: none;
                border-bottom: 1px solid $colorMain;
                font-size: 16px;
                font-weight: 500;
            }
            p{
                color: red;
                font-weight: 600;
            }
            .sigCanvas{
                width: 330px;
                height: 200px;
                border: 1px solid $colorMain;
            }
            .container-btn-canvas{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .btnN{
                    margin: 15px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}
.container-reserva-detalle{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 36px;
        margin: 10px;
        font-family: 'Roboto', sans-serif;
        margin-top: 50px;
    }
    table{
        width: 700px;
        text-align: center;
        .w100{
            width: 100%;
        }
        tr{
            width: 100%;
            height: auto;
            span{
                font-weight: 500;
            }
            td{
                padding: 5px 10px;
                line-height: 18px;
                text-align: left;
                font-size: 14px;
                img{
                    min-height: 200px;
                    max-height: 200px;
                    margin: 30px 0px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    .reserva{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .container-reserva{
            width: 500px;
            height: auto;
            margin: 100px 0px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h2{
                font-size: 34px;
            }
            h4{
                font-size: 14px;
            }
            form{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 50px 0px;
                article{
                    width: 100%;
                    height: auto;
                    margin: 20px;
                    font-size: 16px;
                }
                .fecha-form{
                    width: 62px;
                    height: auto;
                    border: none;
                    margin-left: 6px;
                    font-size: 14px;
                }
                .input-form{
                    width: 100%;
                    margin: 10px 0px 20px 0px;
                    border: none;
                    border-bottom: 1px solid $colorMain;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}