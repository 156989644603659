section{
    .procedimiento{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            width: 100%;
            text-align: center;
            font-size: 34px;
        }
        h4{
            width: 100%;
            text-align: center;
            font-size: 12px;
        }
        .container-procedimiento{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            margin: 50px 0px;
            .img{
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 20px;
                img{
                    max-width: 80%;
                }
            }
            .detalle-procedimiento{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .titulo{
                    font-size: 26px;
                    font-weight: 400;
                }
                article{
                    max-width: 100%;
                    padding: 0px 30px 20px 30px;
                    font-size: 18px;
                    line-height: 36px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .procedimiento{
            h2{
                font-size: 40px;
            }
            .container-procedimiento{
                flex-direction: row;
                align-items: center;
                .img{
                    width: 50%;
                    justify-content: right;
                    padding: 0px 20px;
                    img{
                        max-width: 500px;
                    }
                }
                .detalle-procedimiento{
                    width: 50%;
                    article{
                        max-width: 600px;
                        padding: 0px 20px 20px 20px;
                    }
                }
            }
        }
    }
}