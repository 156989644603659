section{
    .procedimientos{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h3{
            text-align: left;
            font-size: 30px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        img{
            max-width: 80%;
            margin: 30px;
        }
        .list-procedimientos{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-top: 50px;
            .container-procedimientos{
                width: 300px;
                height: auto;
                display: flex;
                flex-direction: column;
                margin: 20px;
                .button{
                    border: none;
                    background-color: transparent;
                    font-size: 16px;
                    padding: 5px 0px;
                    margin: 5px 0px;
                    text-align: left;
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .procedimientos{
            h2{
                font-size: 40px;
            }
        }
    }
}