section{
    .tratamientos{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        background-color: $colorGray;
        position: relative;
        .instagram{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: $colorGray;
            padding: 40px;
            h2{
                text-align: center;
                font-size: 34px;
                color: $colorMain;
            }
            h2:hover{
                color: #666;
                transition: 0.6s;
            }
            .social{
                width: 50px;
                height: 50px;
                margin-right: 15px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                border-radius: 50%;
                svg{
                    fill: $colorMain;
                    width: 26px;
                    font-size: 26px;
                }
            }
            .social:hover{
                svg{
                    fill: #666;
                    transition: 0.6s;
                }
            }
        }
        .container-tratamientos{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $colorWhite;
            padding-bottom: 150px;
            .banner-tratamientos{
                width: 100%;
                height: 500px;
                display: flex;
                align-items: center;  
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;    
                h2{
                    font-weight: 900;
                    font-size: 36px;
                }
            }
            .container{
                width: 90%;
                height: auto;
                margin-top: -100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $colorGray;
                z-index: 900;
                video{
                    width: 100%;
                    height: auto;
                }
                .img{
                    width: 100%;
                    height: auto;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
                .swiper-container {
                    width: 100%;
                    height: auto;
                    display: flex;
                    .swiper-button-next, .swiper-button-prev {
                        color: $colorMain; 
                    }
                    .swiper-pagination-bullet-active{
                        background-color: $colorMain;
                    }
                    .swiper-slide {	
                        width: auto;
                        height: auto;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        padding: 50px;
                        h3{
                            text-align: center;
                            font-size: 26px;
                            font-weight: 700;
                            text-align: center;
                        }
                        h4{
                            text-align: center;
                            font-size: 20px;
                            font-weight: 400;
                            text-align: center;
                        }
                        article{
                            padding: 20px;
                            font-size: 16px;
                            line-height: 22px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .tratamientos{
            .container-tratamientos{
                .container{
                    width: 80%;
                    flex-direction: row;
                    video{
                        width: auto;
                        height: 700px;
                    }
                    .img{
                        width: auto;
                        height: 700px;
                        img{
                            width: auto;
                            height: 700px;
                        }
                    }
                }
            }
        }
    }    
}


