section{
    .home-banner-dr-luque{
        width: 100%;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-position: 77%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 260px;
        .logo-luque-banner-clinique{
            width: 200px;
            margin: 50px;
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .home-banner-dr-luque{
            background-position: center;
            padding-top: 0;
            .logo-luque-banner-clinique{
                width: 300px;
            }
        }
    }
  }