section{
    .galeria{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0px;
        h2{
            text-align: center;
            font-size: 34px;
        }
        h4{
            text-align: center;
            font-size: 12px;
        }
        .categorias-galeria{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 20px;
            .links{
                padding: 15px;
                margin: 10px;
                border-bottom: solid 2px transparent;
            }
            .activeLinks{
                padding: 15px;
                margin: 10px;
                border-bottom: solid 2px $colorMain;
                background-color: $colorMain;
                color: #E8E8E8;
            }
            .links:hover{
                border-bottom: solid 2px $colorMain;
            }
        }
        .swiper-container {
            width: 95vw;
            height: auto;
            padding: 50px;
            display: flex;
            .swiper-button-next, .swiper-button-prev {
                color: $colorMain; 
            }
            .swiper-pagination-bullet-active{
                background-color: $colorMain;
            }
            .swiper-slide {	
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                .container-galeria{
                    width: auto;
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    background-color: $colorGray;
                    padding: 20px 0px;
                    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
                    .img{
                        width: 300px;
                        img{
                            max-width: 100%;
                        }
                    }
                    .info-galeria{
                        width: auto;
                        background-color: $colorGray;
                        padding: 0px 40px;
                        article{
                            width: 300px;
                            font-size: 16px;
                            line-height: 24px;
                            .titulo{
                                font-size: 20px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1000px){
    section{
        .galeria{
            h2{
                font-size: 40px;
            }
            .swiper-container {
                width: 95vw;
                height: auto;
                padding: 50px;
                display: flex;
                .swiper-button-next, .swiper-button-prev {
                    color: $colorMain; 
                }
                .swiper-pagination-bullet-active{
                    background-color: $colorMain;
                }
                .swiper-slide {	
                    .container-galeria{
                        padding: 20px;
                    }
                }
            }
        }
    }
}


